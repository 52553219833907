<template>
  <div>
    <!-- Start Header Area -->

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <div v-if="data">
      <Header>
        <img
          slot="logo"
          src="../../assets/images/logo/Logo-Inflow-Hor-BB@2x.png"
          style="max-width: 80%; max-height: 80%"
        />
      </Header>
      <!-- End Header Area -->
      <div
        class="rn-page-title-area pt--120 pb--190 bg_image bg_image--7"
        data-black-overlay="8"
        v-if="data.media.data"
        :style="{
          backgroundImage:
            'url(' + data.media.data[0].attributes.formats.medium.url + ')',
        }"
      >
        <!-- Start Breadcrump Area  -->
        <v-container>
          <v-row>
            <v-col cols="12">
              <div class="rn-page-title text-center pt--100">
                <h2 class="heading-title theme-gradient">
                  {{ data.name }}
                </h2>
                <!-- <p>{{ data.short_description }}</p> -->
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div
        class="rn-page-title-area pt--120 pb--190 bg_image bg_image--7"
        data-black-overlay="8"
        v-else
        :style="{
          backgroundImage:
            'url(' + 'https://strapi-3-inflow-assets.s3.us-west-2.amazonaws.com/medium_wallpaperbetter_e1ef22eeee.jpg' + ')',
        }"
      >
        <!-- Start Breadcrump Area  -->
        <v-container>
          <v-row>
            <v-col cols="12">
              <div class="rn-page-title text-center pt--100">
                <h2 class="heading-title theme-gradient">
                  {{ data.name }}
                </h2>
                <!-- <p>{{ data.short_description }}</p> -->
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <!-- End Breadcrump Area  -->

      <!-- Start Portfolio Details Area  -->
      <div class="rn-portfolio-details ptb--120 bg_color--1">
        <v-container>
          <v-row>
            <v-col>
              <div class="portfolio-details">
                <div class="inner">
                  <div v-html="compiledMarkdown(data.long_description)"></div>

                  <div class="portfolio-share-link mt--20 pb--70 pb_sm--40">
                    <ul
                      class="social-share rn-lg-size d-flex justify-start liststyle mt--15"
                    >
                      <li v-for="(social, i) in socialList" :key="i">
                        <a
                          target="_blank"
                          :href="social.url"
                          v-html="iconSvg(social.icon)"
                        ></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <!-- End Portfolio Details Area  -->
      <div class="blog-comment-form pb--120 bg_color--1">
        <v-container>
          <v-row>
            <v-col cols="12">
              <div class="inner">
                <h2 class="title mb--40 fontWeight500">Escribenos</h2>
                <ValidationObserver v-slot="{ handleSubmit }">
                  <form @submit.prevent="handleSubmit(onSubmit)">
                    <v-row>
                      <v-col lg="6" md="12" cols="12">
                        <div class="rnform-group">
                          <ValidationProvider
                            name="name"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <label>
                              <input
                                type="text"
                                v-model="formData.name"
                                placeholder="Nombre"
                              />
                              <span class="inpur-error">{{ errors[0] }}</span>
                            </label>
                          </ValidationProvider>
                        </div>
                        <div class="rnform-group">
                          <ValidationProvider
                            name="email"
                            rules="required|email"
                            v-slot="{ errors }"
                          >
                            <label>
                              <input
                                type="text"
                                rules="required|email"
                                v-model="formData.email"
                                placeholder="Email"
                              />
                              <span class="inpur-error">{{ errors[0] }}</span>
                            </label>
                          </ValidationProvider>
                        </div>
                        <div class="rnform-group">
                          <ValidationProvider
                            name="website"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <label>
                              <input
                                type="text"
                                v-model="formData.subject"
                                placeholder="Asunto"
                              />
                              <span class="inpur-error">{{ errors[0] }}</span>
                            </label>
                          </ValidationProvider>
                        </div>
                      </v-col>
                      <v-col lg="6" md="12" cols="12">
                        <div class="rnform-group">
                          <ValidationProvider
                            name="comment"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <label>
                              <textarea
                                v-model="formData.message"
                                placeholder="Comentario"
                              ></textarea>
                              <span class="inpur-error">{{ errors[0] }}</span>
                            </label>
                          </ValidationProvider>
                        </div>
                      </v-col>
                      <v-col cols="12">
                        <div class="blog-submit-btn">
                          <button
                            class="rn-button-style--2 btn_solid"
                            type="submit"
                            value="submit"
                          >
                            ENVIAR
                          </button>
                        </div>
                      </v-col>
                    </v-row>
                  </form>
                </ValidationObserver>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <!-- Start Related Work  -->

      <!-- End Related Work  -->

      <Footer />
    </div>
  </div>
</template>

<script>
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import feather from "feather-icons";
import axios from "axios";
import { ValidationObserver } from "vee-validate";
import { ValidationProvider } from "vee-validate/dist/vee-validate.full.esm";
import marked from "marked";
export default {
  components: {
    Header,
    Footer,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      overlay: false,
      data: null,
      formData: {
        name: "",
        email: "",
        subject: "",
        message: "",
      },
      items: [
        {
          thumb: require("../../assets/images/portfolio/portfolio-big-03.jpg"),
          src: "https://www.youtube.com/watch?v=ZOoVOfieAF8",
        },
      ],
      socialList: [
        {
          url: "https://www.facebook.com/inflow.cl/",
          icon: "facebook",
        },
        {
          url: "https://www.linkedin.com/company/inflow-cl/",
          icon: "linkedin",
        },
        {
          url: "https://www.instagram.com/inflowchile/",
          icon: "instagram",
        },
      ],
      moreProjects: [
        {
          src: require("../../assets/images/portfolio/related-image-01.jpg"),
          title: "Digital Analysis",
          categorie: "Technique",
        },
        {
          src: require("../../assets/images/portfolio/related-image-02.jpg"),
          title: "Plan Management",
          categorie: "Planning",
        },
      ],
      index: null,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },
    compiledMarkdown(texto) {
      return marked(texto);
    },
    async getData() {
      try {
        const r = await axios(
          `${this.$apiUrl}/api/products/?filters\[slug\]\[$eq\]=${this.$route.params.slug}&populate=*`
        );
        this.data = r.data.data[0].attributes;
        this.overlay = false;
      } catch (err) {
        console.log(err);
        this.overlay = false;
      }
    },
  },
};
</script>
